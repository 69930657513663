import Glide from '@glidejs/glide';

export function initSlideshow() {
    // inicializace hp slideru
    const sliderCarouselEl = document.body.querySelector('.slideshow-big');

    if (!sliderCarouselEl) {
        return;
    }

    if (sliderCarouselEl.classList.contains('slideshow-big-carousel')) {
        const hpSlider = new Glide(sliderCarouselEl, {
            autoplay: 3000,
            perView: 1,
            type: 'carousel',
        })
            .on('run.before', () => {
                const currentSlide = sliderCarouselEl.querySelector('.glide__slide--active');

                const stoppedAtTime = pauseCurrentSlideVideo(
                    currentSlide.querySelector('.glide__slide--active .slideshow-big-item__bg-video')
                );

                if (typeof stoppedAtTime !== 'undefined') {
                    const clonnedVideos = sliderCarouselEl.querySelectorAll(
                        ".glide__slide--clone[data-slide-number='" +
                            currentSlide.dataset.slideNumber +
                            "']  .slideshow-big-item__bg-video"
                    );

                    if (clonnedVideos.length > 0) {
                        clonnedVideos.forEach((videoEl) => {
                            videoEl.currentTime = stoppedAtTime;
                        });
                    }
                }
            })
            .on('run.after', () => {
                playCurrentSlideVideo(
                    sliderCarouselEl.querySelector('.glide__slide--active .slideshow-big-item__bg-video')
                );
            })
            .on('build.after', () => {
                playCurrentSlideVideo(
                    sliderCarouselEl.querySelector('.glide__slide--active .slideshow-big-item__bg-video')
                );
            });

        hpSlider.mount();
    } else {
        playCurrentSlideVideo(sliderCarouselEl.querySelector('.slideshow-big-item__bg-video'));
    }
}

function pauseCurrentSlideVideo(videoEl) {
    if (!videoEl) {
        return;
    }

    videoEl.pause();

    return videoEl.currentTime;
}

function playCurrentSlideVideo(videoEl) {
    if (!videoEl) {
        return;
    }

    videoEl.play();
}
